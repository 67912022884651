import { url as urlHelper } from '@/helpers/helpers';
import { getIdb, idbHelpers, localChanges, localChangesInUse, localIds } from '@/idb';
import CustomerPayment from '@/models/CustomerPayment';
import LocalChange from '@/models/LocalChange';
import LocalChangeState from '@/models/LocalChangeState';
import PaginatedList from '@/models/PaginatedList';
import PaymentProcessor from '@/models/PaymentProcessor';
import PaymentType from '@/models/PaymentType';
import { DateTime } from 'luxon';
import { fetchAllPages, fetchWrap, idbResponse, isIdbResponse, offlineResponse, setPagination } from '../_helpers';

export default {
	/**
	 * Get paginated CustomerPayments by customer id
	 * @param {Object} params request parameters.
	 * @returns (async) Returns a PaginatedList of CustomerPayments if the request was successful, otherwise a Response.
	 */
	async getPaginated({ customerId, startDate = undefined, endDate = undefined, limit = undefined, start = undefined } = {}) {
		const query = setPagination(limit, start);
		if (typeof customerId === 'number') {
			query.customerId = customerId;
		}
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		} else {
			startDate = undefined;
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		} else {
			endDate = undefined;
		}
		const url = urlHelper('/api/CustomerPayments', query);
		let response, data = null;
		try {
			response = await fetchWrap(url);
			if (response.ok) { data = await response.json(); }
		} catch {
			const idb = localChangesInUse.value ? await getIdb() : null;
			if (idb) {
				const filteredData = (await idbHelpers.getFiltered(idb, 'customerPayments', x => {
					if (x.customerId === query.customerId) {
						x.timestamp = DateTime.fromISO(x.timestamp);
						return (!startDate || x.timestamp >= startDate) && (!endDate || x.timestamp < endDate);
					}
					return false;
				}));
				data = {
					start: query.start,
					limit: query.limit,
					totalCount: filteredData.length,
					data: filteredData
						.sort((a, b) => b.timestamp - a.timestamp)
						.slice(query.start, query.start + query.limit),
				};
				response = data ? idbResponse(200) : idbResponse(404);
			} else {
				response = offlineResponse();
			}
		}
		if (response.ok) {
			return new PaginatedList(data, x => new CustomerPayment(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get all CustomerPayments by customer id
	 * @returns (async) Returns an array of CustomerPayments if the request was successful, otherwise a Response.
	 */
	async getAll({ customerId, startDate = undefined, endDate = undefined } = {}) {
		const query = {};
		if (typeof customerId === 'number') {
			query.customerId = customerId;
		}
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		const url = urlHelper('/api/CustomerPayments', query);
		let response, data = [];
		try {
			response = await fetchAllPages(url, x => data.push(x));
		} catch {
			const idb = localChangesInUse.value ? await getIdb() : null;
			if (idb) {
				data = await idb.getAll('customerPayments');
				response = idbResponse(200);
			} else {
				response = offlineResponse();
			}
		}
		if (response.ok) {
			return data.map(x => new CustomerPayment(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get a CustomerPayment
	 * @param {Number} id CustomerPayment ID
	 * @returns (async) Returns a CustomerPayment if the request was successful, otherwise a Response.
	 */
	async getById(id) {
		let response, data = null;
		try {
			response = await fetchWrap('/api/CustomerPayments/' + id);
			if (response.ok) { data = await response.json(); }
		} catch {
			const idb = localChangesInUse.value ? await getIdb() : null;
			if (idb) {
				data = await idb.get('customerPayments', id);
				response = data ? idbResponse(200) : idbResponse(404);
			} else {
				response = offlineResponse();
			}
		}
		if (response.ok) {
			return new CustomerPayment(data);
		} else {
			throw response;
		}
	},
	/**
	 * Create a CustomerPayment
	 * @param {CustomerPayment} model CustomerPayment to create.
	 * @returns (async) Returns the new CustomerPayment if the request was successful, otherwise a Response.
	 */
	async createPayment(model) {
		const idb = localChangesInUse.value ? await getIdb() : null;
		let response, data;
		if (model.id < 0 && (
			!idb ||
			await localIds.mapLocalId(idb, 'customerPayments', model.id) > 0 ||
			!await localChanges.get(idb, LocalChange.getKey('customerPayments', model.id)) ||
			await idb.count('customerPayments', model.id) === 0
		)) {
			// does not exist in idb, so it already got submitted or deleted
			if (idb) {
				await localChanges.deleteChange(idb, LocalChange.getKey('customerPayments', model.id));
				await idb.delete('customerPayments', model.id);
			}
			response = idbResponse(400, { title: 'Duplicate payment detected and rejected' });
		}

		if (!response) {
			try {
				response = await fetchWrap('/api/CustomerPayments', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(model),
				});
				if (response.ok) { data = await response.json(); }
			} catch {
				if (
					idb &&
					model.paymentProcessor === PaymentProcessor.none &&
					(model.paymentType === PaymentType.cash || model.paymentType === PaymentType.check || model.paymentType === PaymentType.accountBalance)
				) {
					data = {};
					data.id = await localIds.getNewId(idb, 'customerPayments');
					data.timestamp = DateTime.now().toISO();
					data.customerId = model.customerId;
					data.amount = model.amount;
					data.paymentType = model.paymentType;
					data.paymentCardBrand = model.paymentCardBrand;
					data.paymentReference = model.paymentReference;
					data.paymentProcessor = model.paymentProcessor;
					data.transactionId = model.transactionId;
					data.notes = model.notes;
					data.workOrderIds = model.workOrderIds ? Array.from(model.workOrderIds) : [];
					await idb.add('customerPayments', data);
					await localChanges.add(idb, new LocalChange({
						storeName: 'customerPayments',
						id: data.id,
						state: LocalChangeState.added,
						data: {
							customerId: data.customerId,
							workOrderIdsCount: data.workOrderIds.length,
						}
					}));
					// update customer
					const idbCustomer = await idb.get('customers', data.customerId);
					if (idbCustomer) {
						let modified = false;
						if (data.workOrderIds.length === 0) {
							// adjusting account balance
							idbCustomer.accountBalance = idbCustomer.accountBalance + data.amount;
							modified = true;
						} else if (data.paymentType === PaymentType.accountBalance) {
							// paying from account balance
							idbCustomer.accountBalance = idbCustomer.accountBalance - data.amount;
							modified = true;
						}
						if (modified) {
							await idb.put('customers', idbCustomer);
							await localChanges.add(idb, new LocalChange({ storeName: 'customers', id: idbCustomer.id, state: LocalChangeState.modifiedIndirectly }));
						}
					}
					// update work orders
					for (const woId of data.workOrderIds) {
						const idbWorkOrder = await idb.get('workOrders', woId);
						if (idbWorkOrder) {
							idbWorkOrder.isPaid = true;
							// idbWorkOrder.paymentId = idbModel.id;
							await idb.put('workOrders', idbWorkOrder);
							await localChanges.add(idb, new LocalChange({ storeName: 'workOrders', id: idbWorkOrder.id, state: LocalChangeState.modifiedIndirectly }));
						}
					}
					response = idbResponse(200);
				} else {
					response = offlineResponse();
				}
			}
		}
		if (response.ok) {
			if (idb && !isIdbResponse(response)) {
				await localIds.addLocalIdMap(idb, 'customerPayments', model.id, data.id);
				await idb.delete('customerPayments', model.id);
				await localChanges.deleteChange(idb, LocalChange.getKey('customerPayments', model.id));
			}
			return new CustomerPayment(data);
		} else {
			return response;
		}
	},
	/**
	 * Create a CustomerPayment
	 * @param {CustomerPayment} model CustomerPayment to create.
	 * @returns (async) Returns the new CustomerPayment if the request was successful, otherwise a Response.
	 */
	async createRefund(model) {
		let response;
		try {
			response = await fetchWrap('/api/CustomerPayments/Refund', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new CustomerPayment(await response.json());
		} else {
			return response;
		}
	},
};
