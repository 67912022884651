import PaymentCardBrand from './PaymentCardBrand.js';
import PaymentProcessor from './PaymentProcessor.js';
import { getArrayFromDto as getArray, getDateTimeFromDto as getDate, getValueFromDto as getValue } from './_helpers.js';

export default class CustomerPayment {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.timestamp = getDate(dto, 'timestamp', null);
		this.customer = null;
		this.customerId = getValue(dto, 'customerId', 'number', null);
		this.amount = getValue(dto, 'amount', 'number', 0);
		this.paymentType = getValue(dto, 'paymentType', 'number', null);
		this.paymentCardBrand = getValue(dto, 'paymentCardBrand', 'number', PaymentCardBrand.none);
		this.paymentReference = getValue(dto, 'paymentReference', 'string', null);
		this.paymentProcessor = getValue(dto, 'paymentProcessor', 'number', PaymentProcessor.none);
		this.paymentProcessorData = {};
		this.transactionId = getValue(dto, 'transactionId', 'string', null);
		this.notes = getValue(dto, 'notes', 'string', null);
		this.workOrders = getArray(dto, 'workOrders', []);
		this.workOrderIds = getArray(dto, 'workOrderIds', []);

		Object.defineProperty(this, 'customer', { enumerable: false });
		// Object.defineProperty(this, 'timestamp', { enumerable: false });
		// Object.defineProperty(this, 'transactionId', { enumerable: false });
	}
}
